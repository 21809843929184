import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ translator, myRef }) => {

    const technologiesImgs = ['HTML5_Badge.svg', 'css3.svg', 'js.svg', 'MongoDB_Logo.svg', 'expressjs-ar21.svg', 'logo512.png', 'redux.svg', 'node.svg', 'bootstrap.png', 'heroku.png', 'firebase.png']
    const generateTechnologies = (techs) => {
        return techs.map((tech) => {
            const classes = `${tech.toLowerCase().slice(0, tech.lastIndexOf('.'))} logo--tech`;
            return <span key={tech}><img className={classes} src={tech} alt={tech.slice(0, tech.lastIndexOf('.'))} /></span>

        });
    }

    return (
        <div className='PictureDescrip-div' ref={myRef}>
            <img className='unai-pic' src='foto_unai_ill.svg' alt='unai-pic' />
            <div>
                <h2 className='p-description'>{translator('fullstackTitle')}</h2>
                <hr className='hr-desktop' />
                <p className='webDesigner-p'>{translator('webDesigner')}</p>
                <div className='softSkills-list'>
                    {generateTechnologies(technologiesImgs)}
                </div>
            </div>

        </div>
    )
}

Header.propTypes = {
    translator: PropTypes.func.isRequired
}

export default Header
