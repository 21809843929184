import React from 'react'
import PropTypes from 'prop-types'

const WorkExperience = ({ translator }) => {
    return (
        <div className='work-div' id='WorkExp'>
            <h2 className='h2-About'>{translator('workExperience')}</h2>
            <div className='work-icons--div'>
                <a className='ml-3' href='https://github.com/unai6'>
                    <i className="fab fa-github linkgit-item mr-2"></i>
                    <span className='work--icons'>
                        Github
                    </span>
                </a>
                <a className='ml-3' href='https://www.linkedin.com/in/unai-gonzalez-escalante-7b508770/'>
                    <i className="fab fa-linkedin work-exp-linkedin mr-2"></i>
                    <span className='work--icons'>
                        Linkedin
                    </span>
                </a>
            </div>

            <hr className='workexp-hr' />
            <div className='about-info mt-4'>
                <div>
                    <span className='date-works text-light'>03/20 {translator('current')}</span>
                    <p className='p-info'>
                        <b className='text-light'>Freelance</b><br />
                        {translator('work-exp-1')}
                    </p>

                    <span className='date-works text-light'>09/18-02/19</span>
                    <p className='p-info'>
                        <b className='text-light'>{translator("work-exp-place-2")}</b> <br />
                        <b>{translator("work-exp-title-2")}</b> <br />
                        {translator("work-exp-2")}
                    </p>
                    <p className='p-info'>Molins de Rei, {translator('spain')}</p>

                    <span className='date-works text-light'>09/17-06/18 </span>
                    <p className='p-info'>
                        <b className='text-light'>{translator("work-exp-place-2")}</b> <br />
                        <b>{translator("work-exp-title-3")}</b><br />
                            - {translator("work-exp-3-info-1")} <br />
                            - {translator("work-exp-3-info-2")}  <br />
                            - {translator("work-exp-3-info-3")}  <br />
                            - {translator("work-exp-3-info-4")}
                    </p>
                    <p className='p-info'>Molins de Rei, {translator('spain')}</p>

                    <span className='date-works text-light'>05/16-12/17 </span>
                    <p className='p-info'>
                        <b className='text-light'>Loreto Abat Oliba</b> <br />
                        <b>{translator("work-exp-title-3")}</b> <br />
                            - {translator("work-exp-4-info-1")}
                    </p>
                    <a className='btn btn-info a-cv' href='https://res.cloudinary.com/dg9lolom1/image/upload/v1615461894/unai%20personal%20web/Unai_Gonzalez_CV_iusbxe.pdf' download>GET MY CV <i className="fas fa-file-pdf"></i></a>
                </div>
            </div>
        </div>
    )
}

WorkExperience.propTypes = {
    translator: PropTypes.func.isRequired
}

export default WorkExperience
