import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Navbar = ({ translator, spanishHandler, englishHandler, english, spanish }) => {
    const [showLanguage, setShowLanguages] = useState(false);
    const [openNav, setOpenNav] = useState(false);

    const openNavHandler = () => {
        setOpenNav(!openNav);
    }

    return (
        <nav className="navbar navbar-expand-lg w-100">
            <button onClick={openNavHandler} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className='navbar-toggler-icon'><i className={openNav ? 'fas fa-times' : 'fas fa-bars' }></i></span>
            </button>
            <>
                <div className='language-wrapper' onClick={() => setShowLanguages(!showLanguage)}>
                    {
                        (showLanguage || spanish) &&
                        <span className=' ml-3 mr-3 text-white' onClick={() => spanishHandler('es')}> <img src='/spain-flag-round.svg' height='15' width='15' alt='esp' /> ES</span>

                    }
                    {
                        (showLanguage || english) &&
                        <span className=' ml-3 text-white' onClick={() => englishHandler('en')}> <img src='/United-kingdom_flag_icon_round.svg' height='15' width='15' alt='en' /> EN</span>
                    }
                </div>

                {
                    /*arrows up and down*/
                    showLanguage ?
                        <i onClick={() => setShowLanguages(!showLanguage)} className="fas fa-angle-up icon-lang text-dark"></i>
                        :
                        <>
                            {
                                !spanish && !english ?
                                    <div className='language-wrapper--init' >
                                        <span className=' ml-3 text-white'> <img src='/United-kingdom_flag_icon_round.svg' height='15' width='15' alt='EN' /> EN</span>
                                        <span><i onClick={() => setShowLanguages(!showLanguage)} className="fas fa-angle-down icon-lang--init text-white"></i></span>
                                    </div>
                                    :
                                    <i onClick={() => setShowLanguages(!showLanguage)} className=" d-inline fas fa-angle-down icon-lang text-white"></i>

                            }
                        </>
                }
            </>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav swing-in-top-fwd ml-auto">
                    <li className="nav-item active">
                        <a className="text-light font-weight-bold nav-link" href="#Home">{translator('home')} <span className="sr-only">({translator('current')})</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#About">{translator("about")}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#EducationHistory">{translator('educationHistory')}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#WorkExp">{translator('workExperience')}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#LatestWorks">{translator('latest-works-title')}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#PortFolio">{translator('portfolio')}</a>
                    </li>
                </ul>
                <form className="form-inline my-2 my-lg-0">
                </form>
            </div>
        </nav>
    )
}

Navbar.propTypes = {
    translator: PropTypes.func.isRequired,
    englishHandler: PropTypes.func.isRequired,
    spanishHandler: PropTypes.func.isRequired,
    english: PropTypes.bool.isRequired,
    spanish: PropTypes.bool.isRequired
}

export default Navbar
