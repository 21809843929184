import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from "react-bootstrap/Modal";
import { LinkedinShareButton, WhatsappShareButton } from "react-share";

const Footer = ({ translator }) => {
    const [sharebuttons, setSharebuttons] = useState(false);

    const showSharebuttonsModal = () => {
        setSharebuttons(!sharebuttons)
    }

    const hideShareButtons = () => {
        setSharebuttons(false)
    }

    return (
        <footer className='footer'>
            <div>
                <h2 className='contact-h2'>{translator("contact-title")}</h2>
                <div className='contact-info-div'>
                    <span className=''>
                        <b>
                            <i className="fas fa-phone-square"></i> <a className='contact-info--a' style={{ color: 'black' }} href="tel:+34667237919">+34 667237919</a>
                        </b>
                    </span>
                    <span className=''>
                        <b>
                            <i className="far fa-envelope"></i> <a className='contact-info--a' style={{ color: 'black' }} href="mailto:contact@unaigo.com">contact@unaigo.com</a>
                        </b>
                    </span>

                    <span className='share' onClick={showSharebuttonsModal}>
                        <b>
                            <i className="fas fa-share-alt" ></i> {translator("share")}
                            {
                                sharebuttons ?
                                    <>
                                        <Modal className='' show={sharebuttons} onHide={hideShareButtons}>
                                            <WhatsappShareButton onClickCapture={hideShareButtons} url={"https://unaigo.com"} title={'Share unaigo.com!'}> <i className="fab fa-whatsapp-square"></i> <span className='share-text'>{translator("whatsapp-share")}</span></WhatsappShareButton>
                                            <LinkedinShareButton onClickCapture={hideShareButtons} title={"Share unaigo.com!"} url={"https://unaigo.com"} source={'https://unaigo.com'}> <i className="fab fa-linkedin share-btn"></i> <span className='share-text'>{translator("linkedin-share")}</span></LinkedinShareButton>
                                        </Modal>
                                    </>
                                    :
                                    null
                            }
                        </b>
                    </span>
                </div>
                <p className='unai-ad'>
                    <i>@Unai Gonzalez Escalante 2020</i>
                </p>
            </div>
        </footer>
    )
}

Footer.propTypes = {
    translator: PropTypes.func.isRequired
}

export default Footer
