import React, { Suspense } from 'react';
import './CSS/App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ClientMessages } from './components/ClientMessages';
import { MessageSent } from './components/MessageSent';
import Loader from 'react-loader-spinner';
import { MainMenu } from './components/MainMenu';

export const App = () => {
  return (
    <Suspense fallback={<Loader type="ThreeDots" color="rgb(255, 188, 73)" height={80} width={80} />}>
      <Router>
        <Switch>
          <Route exact path='/message-sent' component={MessageSent} />
          <Route exact path='/clientmessages' component={ClientMessages} />
          <Route exact path='/' component={MainMenu} />
        </Switch>
      </Router>
    </Suspense>
  )
}

