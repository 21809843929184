import React from 'react'
import PropTypes from 'prop-types'

const EducationHistory = ({ translator }) => {
    return (
        <div className='education-div' id='EducationHistory'>
            <div>
                <h2 className='h2-About'>{translator('educationHistory')}</h2>
                <div className='education-info' >
                    <p className='p-info mt-3'>Facultat de Psicologia, Ciències de l'Educació i l'Esport Blanquerna. Universidad Ramon Llull.<br />
                        <b>{translator('education-history-1')}</b></p>

                    <p className='p-info'>Barcelona, {translator('spain')}</p>

                    <p className='p-info'>Ironhack
                        {translator('fullstackTitle')}
                        Web Development Bootcamp, Full Stack
                        {translator('education-history-2')}<br />
                        <b>- {translator('tecnologies-education-history')}: MERN Stack, Javascript, HTML and CSS, Bootstrap, Node.js, MongoDB / Atlas, Express, Handlebars, Cloudinary, React, Github, Heroku, Firebase, Redux, GraphQL.</b>
                    </p>
                    <p className='p-info'>Barcelona, {translator('spain')}</p>
                </div>
            </div>
            <img className='educ-pic' src='estudiosnobg.png' alt='about' />
        </div>
    )
}

EducationHistory.propTypes = {
    translator: PropTypes.func.isRequired
}

export default EducationHistory
