import React from 'react'
import PropTypes from 'prop-types'

const Certifications = ({ translator }) => {
    return (
        <div className='education-div' id='EducationHistory'>
            <img className='cert-pic' src='certnobg.png' alt='about' />

            <div className='cert-wrapper'>
                <h2 className='h2--about'>{translator('certifications')}</h2>
                <ul>
                    <li className='udemy--list'>
                        <img className='udemy mr-3' src='udemy.png' alt='' />
                        <b className='mr-1'>02/11/21-</b>
                        The Complete Javascript Course 2021.
                        </li>
                    <li className='udemy--list'>
                        <img className='udemy mr-3' src='udemy.png' alt='' />
                        <b className='mr-1'>12/11/20-</b>
                        NodeJS Rest-Express MongoDB-Jest javascript Unit/Int Test
                        </li>
                    <li className='udemy--list'>
                        <img className='udemy mr-3' src='udemy.png' alt='' />
                        <b className='mr-1'>12/02/2020-</b>
                            TDD in NodeJS, test guide with Jest.
                        </li>
                </ul>

            </div>
        </div>
    )
}

Certifications.propTypes = {
    translator: PropTypes.func.isRequired
}

export default Certifications
