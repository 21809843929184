import React from 'react'
import PropTypes from 'prop-types'

const PortFolio = ({ translator }) => {

    const technologiesImgs = ['HTML5_Badge.svg', 'css3.svg', 'js.svg', 'MongoDB_Logo.svg', 'expressjs-ar21.svg', 'logo512.png', 'redux.svg', 'node.svg', 'bootstrap.svg.png', 'heroku.png', 'firebase.png', 'mongoose.png', 'handlebars.png', 'jquery.png']

    return (
        <div className='portfolio-div p-3' id='PortFolio'>
            <h2 className='h2-About pt-2'> {translator('portfolio')}</h2>
            <div className='project-wrapper'>
                <div className='project-info'>
                    <p className='p-info'>
                        <b className='text-light'>Gamanfy App - 05-07/2020 </b><br />
                        <span className='d-block mt-2'>{translator("gamanfy-description")}</span> <br />
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[5]} alt={technologiesImgs[5].slice(0, technologiesImgs[5].lastIndexOf('.'))} /></span>
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[3]} alt={technologiesImgs[3].slice(0, technologiesImgs[3].lastIndexOf('.'))} /></span>
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[9]} alt={technologiesImgs[9].slice(0, technologiesImgs[9].lastIndexOf('.'))} /></span>
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[10]} alt={technologiesImgs[10].slice(0, technologiesImgs[10].lastIndexOf('.'))} /></span>
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[7]} alt={technologiesImgs[7].slice(0, technologiesImgs[7].lastIndexOf('.'))} /></span>
                    </p>
                </div>
                <div className='d-flex justify-content-center'>
                    <img className='logo-works-desktop mr-2' src='/caratula_gamanfy-removebg-preview (1).png' alt='logo-gamanfy' />
                    <img className='logo-works-desktop mr-2' src='/gamanfy2.png' alt='logo-gamanfy' style={{height: '287px'}} />
                    <img className='logo-works-desktop' src='/gamanfy3.png' alt='logo-gamanfy' />
                </div>
                <p className='see-live-desktop'>
                    <b>{translator("see-live-app-link")}<a href='https://app.gamanfy.com' className=' text-light'>App Gamanfy</a></b>
                </p>
            </div>

            <div className='project-wrapper'>
                <div className='project-info'>
                    <p className='mt-5 p-info'>
                        <b className='text-light mb-2'>Masks 'R' Us - 03/2020 </b><br />
                        <span className='d-block mt-2'>{translator("masks-ur-us-description")} </span> <br />
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[5]} alt={technologiesImgs[5].slice(0, technologiesImgs[5].lastIndexOf('.'))} /></span>
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[3]} alt={technologiesImgs[3].slice(0, technologiesImgs[3].lastIndexOf('.'))} /></span>
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[9]} alt={technologiesImgs[9].slice(0, technologiesImgs[9].lastIndexOf('.'))} /></span>
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[10]} alt={technologiesImgs[10].slice(0, technologiesImgs[10].lastIndexOf('.'))} /></span>
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[7]} alt={technologiesImgs[7].slice(0, technologiesImgs[7].lastIndexOf('.'))} /></span>
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><b>Cheerio</b></span>
                    </p>
                </div>
                <div className='d-flex justify-content-center'>
                    <img className='logo-works logo-works-desktop' src='/maskrus-removebg-preview.png' alt='logo-mkasrus' />
                    <img className='logo-works logo-works-desktop' src='/masks2.png' alt='logo-mkasrus2' style={{height: '287px'}}/>
                    <img className='logo-works logo-works-desktop' src='/masks3.png' alt='logo-mkasrus3' />
                </div>
                <p className='see-live-desktop'>
                    <b>{translator("see-live-app-link")} <a href='https://masks-r-us.firebaseapp.com/' className='text-light'>Masks 'R' Us</a></b>
                </p>
            </div>

            <div className='project-wrapper'>
                <div className='project-info '>
                    <p className='mt-5 p-info'>
                        <b className='text-light mb-2'>Classy - 02/2020 </b><br />
                        <span className='d-block mt-2'>{translator("classy-info-1")} </span>    
                        <span className='d-block'>{translator("classy-info-2")} </span> <br />
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[7]} alt={technologiesImgs[7].slice(0, technologiesImgs[7].lastIndexOf('.'))} /></span>
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[13]} alt={technologiesImgs[13].slice(0, technologiesImgs[13].lastIndexOf('.'))} /></span>
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[3]} alt={technologiesImgs[3].slice(0, technologiesImgs[3].lastIndexOf('.'))} /></span>
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[12]} alt={technologiesImgs[12].slice(0, technologiesImgs[12].lastIndexOf('.'))} /></span>
                        <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[11]} alt={technologiesImgs[11].slice(0, technologiesImgs[11].lastIndexOf('.'))} /></span>
                    </p>
                </div>
                <div className='d-flex justify-content-center'>
                    <img className='logo-works logo-works-desktop' src='/classy-removebg-preview.png' alt='logo-classy' />
                    <img className='logo-works logo-works-desktop' src='/classy2.png' alt='logo-classy2' style={{height: '287px'}}/>
                    <img className='logo-works logo-works-desktop' src='/classy3.png' alt='logo-classy3' />
                </div>
                <p className='see-live-desktop pb-3'>
                    <b>{translator("see-live-app-link")}  <a href='https://class-y.herokuapp.com/home/' className='text-light'>Classy</a></b>
                </p>
            </div>
        </div>
    )
}

PortFolio.propTypes = {
    translator: PropTypes.func.isRequired
}

export default PortFolio
