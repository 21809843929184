import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Editor } from '@tinymce/tinymce-react';
import { useForm } from 'react-hook-form'
import { sendBudget } from '../api/budgetContact';
import { useHistory } from 'react-router-dom';


const Contact = ({ translator }) => {
    const history = useHistory();
    const { handleSubmit, register, errors } = useForm();
    const [infoSent, setInfoSent] = useState(false);
    const [content, setContent] = useState();
    const handleEditorChange = (content, editor) => { setContent(content) };

    const onSubmit = async (data) => {
        try {
            setInfoSent(true)
            data.clientMessage = content
            await sendBudget(data)

            history.push('/message-sent');

        } catch (error) {
            console.log(error);
        }
    };

    const handleLoader = () => {
        setInfoSent(true)
        if (errors) setInfoSent(false)
        setTimeout(() => {
            if (!errors) setInfoSent(false)
        }, 9000);
    }


    return (
        <div className='contact-div'>
            <h2 className='h2-About'>{translator("contact-title-enquieries")}</h2>
            <form className=' w-75 d-block mx-auto' onSubmit={handleSubmit(onSubmit)}>
                <input
                    className={errors.name ? 'border border-danger form-control mt-5 form-fields w-25' : 'border-info w-25 form-control mt-5 form-fields'}
                    name='name'
                    placeholder={translator("name-form")}
                    ref={register({ required: true })}
                />
                {errors.name && <span>{translator("mandatory-field-form-error")}</span>}
                <input
                    className={errors.email ? 'border border-danger form-control mt-5 form-fields w-25' : 'border-info w-25  form-control mt-5 form-fields'}
                    name='email'
                    placeholder={translator("email-form")}
                    ref={register({
                        required: true, pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'La dirección no es válida' }
                    })}
                />
                {errors.email && <span> {errors.email.message ? errors.email.message : translator('mandatory-field-form-error')} </span>}
                <Editor
                    init={{
                        height: 200,
                        plugins: [
                            'advlist autolink lists link image preview charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],

                        toolbar:
                            'undo redo | formatselect | bold italic backcolor| \n' +
                            'alignleft aligncenter alignright alignjustify | \n' +
                            'bullist numlist outdent indent | removeformat | link | image | preview | help',
                        menubar: 'insert | view',
                    }}
                    onEditorChange={handleEditorChange}

                />

                {errors.clientMessage && <span>{translator("mandatory-field-form-error")} </span>}

                {
                    !infoSent
                        ?
                        <button onClick={handleLoader} className='btn-send-form btn-info btn text-light mt-4'> <i className="far fa-envelope"></i> {translator("send-mail")} </button>
                        :

                        <button className='btn-send-form btn text-light mt-4'> <i className="rotate-center fas fa-sync-alt mr-3"></i>{translator("sending-mail")} </button>


                }
            </form>
            {/* <a href='/clientmessages'>see client messages</a> */}
        </div>
    )
}

Contact.propTypes = {
    translator: PropTypes.func.isRequired
}

export default Contact
