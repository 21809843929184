import React from 'react'
import PropTypes from 'prop-types'

const About = ({ translator }) => {
    return (
        <div className='about-div' id='About'>
            <img className='estudios' src='https://images.pexels.com/photos/943096/pexels-photo-943096.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940' alt='ed-pic' />
            <div className='about-info--1 mt-4 '>
                <h2 className='h2--about'>{translator('about')}</h2>
                <hr className='about-hr' />
                <p>{translator('aboutInfounaip1')}</p>
                <p>{translator('aboutInfounaip2')}</p>
                <p>{translator('aboutInfounaip3')}</p>
            </div>
        </div>
    )
}

About.propTypes = {
    translator: PropTypes.func.isRequired
}

export default About
