import React from 'react';
import '../CSS/messageSent.css';
import {useHistory} from 'react-router-dom';

export const MessageSent = () => {
    const history = useHistory();
    return (
        <div className='message-div-parent'>
            <img className='unailogo' src='/unailogo.png' alt='unailogo' />
            <div className="message-sent-container">
                <button className='btn button-back d-block mr-auto' onClick={() => history.push('/')}> Back to menu</button>
                <i className="far fa-envelope"></i> <p className='font-weight-bold message-sent'>Message Sent!</p>
                <p className='font-weight-bold'>Thanks for trusting us your project, you will receive an answer as soon as possible.</p>
            </div>
        </div>
    )
}
