import React, { useEffect, useState } from 'react';
import { clientMessage } from '../api/budgetContact';
import DOMPurify from 'dompurify';

export const ClientMessages = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchInfo = async () => {
            let result = await clientMessage();
            setData(result.data)

        }
        fetchInfo()
    }, [])

    const sanitizer = DOMPurify.sanitize;
    return (

        <div>
            {
                data !== undefined ?

                    data.map((item, index) => {
                        return (
                            <div key={index} dangerouslySetInnerHTML={{ __html: sanitizer(item.clientMessage) }} />
                        )
                    })
                    : null}
        </div>

    )
}
