import React from 'react'
import PropTypes from 'prop-types'

const LatestWorks = ({ translator }) => {
    const technologiesImgs = ['HTML5_Badge.svg', 'css3.svg', 'js.svg', 'MongoDB_Logo.svg', 'expressjs-ar21.svg', 'logo512.png', 'redux.svg', 'node.svg', 'bootstrap.svg.png', 'heroku.png', 'firebase.png', 'mongoose.png', 'handlebars.png', 'jquery.png']

    return (
        <div className='latestWorks-div' id='LatestWorks'>
            <h2 className='h2-About'>{translator('latest-works-title')} </h2>
            <div className='info-wrapper'>
                <p className='latest-works-p'>
                    <b>Gamanfy App - 07/2020 </b><br />
                    {translator("gamanfy-description")} <br />
                    <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[5]} alt={technologiesImgs[5].slice(0, technologiesImgs[5].lastIndexOf('.'))} /></span>
                    <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[3]} alt={technologiesImgs[3].slice(0, technologiesImgs[3].lastIndexOf('.'))} /></span>
                    <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[9]} alt={technologiesImgs[9].slice(0, technologiesImgs[9].lastIndexOf('.'))} /></span>
                    <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[10]} alt={technologiesImgs[10].slice(0, technologiesImgs[10].lastIndexOf('.'))} /></span>
                    <span className='rounded pr-1 pl-1 mt-2 mr-1 d-inline-block span-techs text-light'><img className='portfolio--techs' src={technologiesImgs[7]} alt={technologiesImgs[7].slice(0, technologiesImgs[7].lastIndexOf('.'))} /></span>
                </p>
            </div>
            <div className='d-flex justify-content-center'>
                <img className='logo-works-desktop mr-2' src='/caratula_gamanfy-removebg-preview (1).png' alt='logo-gamanfy' />
                <img className='logo-works-desktop mr-2' src='/gamanfy2.png' alt='logo-gamanfy' style={{height: '287px'}}/>
                <img className='logo-works-desktop' src='/gamanfy3.png' alt='logo-gamanfy' />
            </div>
                <p className='see-live-desktop'>
                    <b>{translator("see-live-app-link")}<a href='https://app.gamanfy.com' className=' text-info'>App Gamanfy</a></b>
                </p>
        </div>
    )
}

LatestWorks.propTypes = {
    translator: PropTypes.func.isRequired
}

export default LatestWorks
