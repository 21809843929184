import React, { useState, useEffect, useRef } from 'react';
import NET from 'vanta/dist/vanta.net.min';
import * as THREE from 'three'
import '../CSS/App.css';
import { useTranslation } from 'react-i18next';
import 'semantic-ui-css/semantic.min.css';
import Footer from './Footer';
import Navbar from './Navbar';
import About from './PersonalInfo/About';
import Header from './Header';
import EducationHistory from './PersonalInfo/EducationHistory';
import LatestWorks from './PersonalInfo/LatestWorks';
import Contact from './Contact';
import WorkExperience from './PersonalInfo/WorkExperience';
import PortFolio from './PersonalInfo/PortFolio'
import Certifications from './PersonalInfo/Certifications';

export const MainMenu = () => {
    const [t, i18n] = useTranslation();
    const [english, setEnglish] = useState(false);
    const [spanish, setSpanish] = useState(false);
    const [vantaEffect, setVantaEffect] = useState(0)
    const myRef = useRef(null)

    const englishHandler = (lang) => {
        i18n.changeLanguage(lang);
        let spanish = sessionStorage.getItem('spanish');
        setEnglish(true);
        setSpanish(false);
        sessionStorage.setItem("english", true);
        if (spanish) sessionStorage.setItem('spanish', false);

    }

    const spanishHandler = (lang) => {  
        i18n.changeLanguage(lang);
        let english = sessionStorage.getItem('english');
        setSpanish(true);
        setEnglish(false);
        sessionStorage.setItem("spanish", true);
        if (english) sessionStorage.setItem('english', false);
    }


    useEffect(() => {
        if (!vantaEffect) {
            setVantaEffect(
                NET({
                    THREE,
                    el: myRef.current,
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: true,
                    minHeight: 500.00,
                    minWidth: 1000.00,
                    scale: 1.00,
                    scaleMobile: 1.00,
                    backgroundColor: '#37C1CE',
                    color: '#126b73'
                }))
        }
        return () => {
            if (vantaEffect) vantaEffect.destroy()
        }
    }, [vantaEffect]);

    return (
        <main>
            <Navbar translator={t} spanishHandler={spanishHandler} englishHandler={englishHandler} english={english} spanish={spanish} />
            <Header translator={t} myRef={myRef} />
            <About translator={t} />
            <EducationHistory translator={t} />
            <Certifications translator={t} />
            <WorkExperience translator={t} />
            <LatestWorks translator={t} />
            <PortFolio translator={t} />
            <Contact translator={t} />
            <Footer translator={t} />
        </main>
    )
}
